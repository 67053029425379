.work-page {
    width: 100%;
    margin-top: 6vh;
    height: 94vh;
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;
    
}

.half-page{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    cursor: pointer;
    transition: 0.4s ease-in-out;
}

.half-page::before {
    content:'';
    position: absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    background: inherit;
    transition: inherit;
}

.half-page:hover::before {
    transform: scale(1.1);
}

.content {
    position: relative;
}

.content h2 {
    font-size: 2em;
    background-color: var(--background);
    border: 2px solid var(--primary);
    padding: 0.5em 1.5em;
}

/* RESPOSNIVE */

/* SMARTPHONES PORTRAIT */
@media only screen and (max-width: 767px) {

    .work-page {
        height: 100vh;
        flex-direction: column;
        position: static;
        margin: 0;
    }

    .half-page {
        width: 100%;
        height: 50%;
    }

    .content h2 {
        font-size: 1.5em;
    }

}

/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 480px) and (max-width: 900px) and (orientation: landscape) {
    
    .work-page {
        position: static;
        height: 100vh;
        margin: 0;
    }
    
    .content h2 {
        font-size: 1.5em;
    }

}

/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) and (max-width: 1100px) and (orientation: portrait) {
    
    .work-page {
        height: 100vh;
        flex-direction: column;
        position: static;
        margin: 0;
    }

    .half-page {
        width: 100%;
        height: 50%;
    }

    .content h2 {
        font-size: 1.5em;
    }

}

/* TABLET LANDSCAPE // DESKTOP */
@media only screen and (min-width: 901px) and  (max-width: 1400px){

}

