.project-header {
    font-size: 3em;
    padding: 0.5em 0 1em 0;
}

.project-subheader {
    font-size: 3em;
    padding: 2em 0 0.5em 0;
}

.hero {
    width: 70%;
}

.project-description {
    width: 28%;
    text-align: left;
    font-size: 1.5em;
    margin-left: 2%;
}

.app-description {
    font-size: 1.5em;
    margin: 0 20%;
}

.app-description a {
    color: var(--primary);
    text-decoration: none;
}

.app-description a:hover {
    text-decoration: underline;
    opacity: 0.9;
}

.features-list li {
    font-size: 1.2em;
    font-family: var(--body-text);
    list-style: none;
}

.features-list > li > span {
    color: var(--primary);
}

.flex-container {
    display: flex;
    width: 90%;
    margin: 0 5%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.one-row {
    width: 88%;
    margin: 0.5% 5%;
}

.two-row {
    width: 49%;
    margin: 0.5%;
}

.three-row {
    width: 32%;
    margin: 0.5%;
}

.two-column-grid {
    justify-content: center;
    column-count: 2;
    line-height: 0;
    column-gap: 1%;
    text-align: center;
    margin: 2em 5%;
}

.grid-image {
    width: 100%;
    margin-bottom: 2%;
    vertical-align: middle;
}

.video-demo {
    height: 80vh;
    width: auto;
    margin: 3vh 0;
}

/* RESPOSNIVE */

/* SMARTPHONES PORTRAIT */
@media only screen and (max-width: 767px) and (orientation: portrait){

    .project-header {
        font-size: 1.5em;
    }

    .project-subheader {
        font-size: 1.5em;
    }

    .hero {
        width: 100%;
        margin-bottom: 2em;
    }

    .project-description {
        width: 100%;
        font-size: 1em;
    }

    .flex-container {
        width: 75%;
        margin: 0 12.5%;
    }

    .one-row {
        width: 75%;
    }

    .two-row {
        width: 100%;
        margin-bottom: 1.5%;
    }

    .three-row {
        width: 100%;
        margin-bottom: 1.5%;
    }

    .two-column-grid {
        column-count: 1;
        margin: 2em 12.5%;
    }

    .video-demo {
        width: 85%;
        height: auto;
    }

    .app-description {
        font-size: 1em;
        margin: 0 12.5%;
    }

    .features-list {
        font-size: 0.8em;
        margin: 0 15%;
    }


}

/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 421px) and (max-width: 900px) and (orientation: landscape) {
    
    .project-header {
        font-size: 2em;
    }

    .project-subheader {
        font-size: 2em;
    }

    .hero {
        width: 100%;
        margin-bottom: 2em;
    }

    .project-description {
        width: 100%;
        font-size: 1.2em;
    }

    .flex-container {
        width: 75%;
        margin: 0 12.5%;
    }

    .one-row {
        width: 75%;
    }

    .two-row {
        width: 100%;
        margin-bottom: 0.5%;
    }

    .three-row {
        width: 100%;
        margin-bottom: 1.5%;
    }

    .two-column-grid {
        column-count: 1;
        margin: 2em 12.5%;
    }

    .video-demo {
        width: 90%;
        max-height: 90vh;
    }

    .app-description {
        font-size: 1.2em;
        margin: 0 12.5%;
    }

    .features-list {
        font-size: 1em;
        margin: 0 15%;
    }

}

/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) and (max-width: 1100px) and (orientation: portrait) {
    
    .project-header {
        font-size: 2em;
    }

    .project-subheader {
        font-size: 2em;
    }

    .hero {
        width: 100%;
        margin-bottom: 2em;
    }

    .project-description {
        width: 100%;
        font-size: 1.2em;
    }

    .flex-container {
        width: 75%;
        margin: 0 12.5%;
    }

    .one-row {
        width: 75%;
    }

    .three-row {
        width: 100%;
        margin-bottom: 1.5%;
    }

    .two-column-grid {
        column-count: 1;
        margin: 2em 12.5%;
    }

    .video-demo {
        width: 90%;
        max-height: 90vh;
    }

    .app-description {
        font-size: 1.2em;
        margin: 0 12.5%;
    }

    .features-list {
        font-size: 1em;
        margin: 0 15%;
    }

}

/* TABLET LANDSCAPE // DESKTOP */
@media only screen and (min-width: 901px) and  (max-width: 1400px){

    .project-header {
        font-size: 2em;
    }

    .project-subheader {
        font-size: 2em;
    }

    .hero {
        width: 100%;
        margin-bottom: 2em;
    }

    .project-description {
        width: 100%;
        font-size: 1.2em;
    }

    .flex-container {
        width: 75%;
        margin: 0 12.5%;
    }

    .one-row {
        width: 75%;
    }

    .three-row {
        width: 100%;
        margin-bottom: 1.5%;
    }

    .two-column-grid {
        column-count: 1;
        margin: 2em 12.5%;
    }

    .video-demo {
        width: 90%;
        max-height: 90vh;
    }

    .app-description {
        font-size: 1.2em;
        margin: 0 12.5%;
    }

    .features-list {
        font-size: 1em;
        margin: 0 15%;
    }


}