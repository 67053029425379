.about-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-bottom: 6em;
}

.about-text {
    font-size: 1.2em;
    text-align: left;
    width: 30%;
    margin-left: 5%;
}

.about-text a {
    color: var(--secondary);
    text-decoration: none;
}

.about-text a:hover {
    color: var(--primary);
}

.about-skills {
    width: 20%;
    text-align: left;
    border: 2px solid var(--primary);
    padding: 5%;
    margin-left: 3%;
    font-size: 1.5em;
    font-family: var(--body-text);
}

.about-skills li, .about-text li {
    list-style: none;
    font-family: var(--body-text);
}

.about-image {
    width: 30%;
    height: 75vh;
    background-size: cover;
    background-position: center center;

}

.about-image img {
    width: 100%;
}

/* RESPOSNIVE */

/* SMARTPHONES PORTRAIT */
@media only screen and (max-width: 767px) {

    .about-container {
        margin-bottom: 3em;
    }

    .about-text {
        width: 75%;
        font-size: 1em;
    }

    .about-image {
        height: 40vh;
        width: 75%;
        margin-bottom: 2em;
    }

    .about-skills {
        width: 75%;
        text-align: center;
        margin: 2em 0;
    }

}

/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 421px) and (max-width: 900px) and (orientation: landscape) {

    .about-container {
        margin-bottom: 3em;
    }

    .about-text {
        width: 75%;
        font-size: 1em;
    }

    .about-image {
        height: 40vw;
        width: 50%;
        margin-bottom: 2em;
    }

    .about-skills {
        width: 75%;
        text-align: center;
        margin: 2em 0;
    }
    
}

/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) and (max-width: 1100px) and (orientation: portrait) {

    .about-container {
        margin-bottom: 3em;
    }

    .about-text {
        width: 65%;
        font-size: 1.4em;
    }

    .about-image {
        height: 50vh;
        width: 65%;
        margin-bottom: 2em;
    }

    .about-skills {
        width: 65%;
        text-align: center;
        margin: 2em 0;
    }
    
}

/* TABLET LANDSCAPE // DESKTOP */
@media only screen and (min-width: 901px) and  (max-width: 1400px){

}