
.home-page {
    display: flex;
    flex-wrap: wrap;
    z-index: -1;
    height: 85vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--background);
}

.logo {
    width: 100%;
}

.logo img {
    width: 20%;
}

.details {
    width: 100%;
    padding: 2em 0;
    font-size: 1.5em;
}

.button {
    border: none;
    border-radius: 4em;
    background-color: var(--primary);
    padding: 1em 2em;
    color: ghostwhite;
    font-size: 1em;
    outline: none;
    font-family: var(--header-text);
    font-size: 1.2em;
    font-weight: bold;
}

.button:hover {
    cursor: pointer;
    opacity: 0.9;
}

/* RESPOSNIVE */

/* SMARTPHONES PORTRAIT */
@media only screen and (max-width: 767px) {

    .home-page {
        height: 60vh;
    }

    .logo img {
        width: 30%;
    }

    .details {
        font-size : 1.2em;
    }

    .button {
        padding: 1em 2em;
    }

}

/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 421px) and (max-width: 900px) and (orientation: landscape) {
    

    .home-page {
        height: 60vh;
    }

    .logo img {
        display: none;
    }

    .details {
        font-size : 1.2em;
    }

    .button {
        padding: 1em 2em;
    }

}

/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) and (max-width: 1100px) and (orientation: portrait) {
    
}

/* TABLET LANDSCAPE // DESKTOP */
@media only screen and (min-width: 901px) and  (max-width: 1400px){

}
