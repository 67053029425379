.nav {
    list-style: none;
    justify-content: center;
    align-items: center;
    background-color: var(--background);
    font-family: var(--header-text);
}

.nav li {
    font-size: 1.5em;
    display: inline-block
}

.nav li img {
    left: 0;
    top: 0;
    position: absolute;
    padding: 1em 1.5em;
    height: 77px;
    width: auto;
}

.link {
    text-decoration: none;
    display: inline-block;
    color: inherit;
    padding: 1em 1.5em;
}

.link:visited {
    text-decoration: none;
    color: inherit;
}

.link:hover {
    background-color: var(--primary);
}

.link-active {
    text-decoration: underline;
}

.toggle-button {
    right: 0;
    position: absolute;
    padding: 1em 1.5em;
}

/* RESPOSNIVE */

/* SMARTPHONES PORTRAIT */
@media only screen and (max-width: 767px) {

    .nav li {
        display: block;
        width: 100%;
        font-size: 1.2em;
    }

    .link {
        padding: 0.5em;
        width: 100%;
    }
    
    .nav li img {
        position: static;
        height: 40px;
        margin-top: 1.2em;
        padding: 0;
    }

    .toggle-button {
        position: absolute;
        top: 0;
        padding-left: 80%;
    }

}

/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 421px) and (max-width: 900px) and (orientation: landscape) {
    
}

/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) and (max-width: 1100px) and (orientation: portrait) {
    
}

/* TABLET LANDSCAPE // DESKTOP */
@media only screen and (min-width: 901px) and  (max-width: 1400px){

}