* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary: #FF0266;
  --secondary: #FFFFFF;
  --background: #212121;
  --header-text: 'Roboto Slab';
  --body-text: 'Open Sans';
}

body {
  background-color: var(--background);
  color: var(--secondary);
}

h1, h2, h3, h4, h5 {
  font-family: var(--header-text);
}

p {
  font-family: var(--body-text);
}
