.footer {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 2em 0;
}

.footer img {
    width: 30px;
    height: auto;
    margin: 1em 1em;
}

.footer img:hover {
    opacity: 0.7;
    cursor: pointer;
}