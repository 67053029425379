.project-grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.project-item {
    width: 30%;
    margin: 0 1%;
}

.project-item img {
    width: 100%;
    height: 100%;
}

.project-item img:hover {
    opacity: 0.8;
    cursor: pointer;
}

.project-item-text {
    margin-top: 2%;
    margin-bottom: 5%;
}

.project-item-text h3 {
    padding-bottom: 0.1em;
}

.project-item-text p {
    padding-bottom: 0.3em;
}

/* RESPOSNIVE */

/* SMARTPHONES PORTRAIT */
@media only screen and (max-width: 767px) {

    .project-grid {
        width: 90%;
        margin: 0 5%;
    }

    .project-item {
        width: 90%;
    }

    .project-item-text {
        font-size: 0.8em;
        margin-bottom: 3%;
    }

}

/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 421px) and (max-width: 900px) and (orientation: landscape) {
    
    .project-item {
        width: 45%;
    }

}

/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) and (max-width: 1100px) and (orientation: portrait) {

    .project-item {
        width: 45%;
    }

}

/* TABLET LANDSCAPE // DESKTOP */
@media only screen and (min-width: 901px) and  (max-width: 1400px){
    
    .project-item {
        width: 45%;
    }

}